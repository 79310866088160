import React from 'react';
import Seo from '../seo';
import BaseContainer from '../components/common/BaseContainer';
import {
  Grid,
  Toolbar,
  Typography
} from '@mui/material';
import { Link as GatsbyLink } from 'gatsby';
import { FiberpayLogoImageCobalt } from '../components/common/FiberpayLogoImage';
import Button from '../components/common/Button';
import { Box } from '@mui/system';
import Link from '../components/common/Link';
import Footer from '../components/common/Footer';

const services = [
  {
    name: 'FiberSplit',
    title:
      'Masowa wypłata do wielu odbiorców zasilana pojedynczym przekazem pieniężnym',
    description:
      'Usługa ta służy do masowej wysyłki środków do jednego lub wielu kontrahentów w prosty i szybki sposób. Zlecenie przekazu utworzysz za pośrednictwem API, które zwróci numer konta bankowego, na które nadawca ma dokonać wpłaty zasilającej poszczególne pozycje na liście przelewów oraz kod tego przekazu. Następnie kolejnymi wywołaniami możesz utworzyć dowolną liczbę przelewów, podając ich dane - w tym odbiorcę, numer jego konta bankowego, kwotę oraz tytuł przelewu. Uruchomienie wysyłki następuje po dokonaniu przelewu w kwocie pokrywającej wartość wszystkich przelewów do wykonania na numer konta i z kodem w tytule przelewu uzyskanymi w odpowiedzi na zlecenie inicjujące. Fiberpay po otrzymaniu przelewu rozpocznie wykonywanie każdego z przelewów indywidualnych zdefiniowanych w zleceniu. Fiberpay powiadomi Twój system po zmianach stanów poszczególnych przelewów (np. rozpoczęty, zakończony, odrzucony).',
    linkUri: '/FiberSplit-20231106.pdf',
  },
  {
    name: 'FiberCollect',
    title:
      'Przyjęcie wpłat od wielu nadawców i przelew zagregowanym przekazem dziennym na wskazane konto bankowe',
    description:
      'Usługa służy do przyjmowania przekazów pieniężnych od jednego lub wielu nadawców. Zlecenie pojedynczego przekazu utworzysz za pośrednictwem API, które zwróci numer konta bankowego, na które nadawca ma dokonać wpłaty oraz kod danego przekazu.Nadawcy wpłacają środki na podane im konta bankowe, podając przy tym indywidualne identyfikatory wygenerowany przez Fiber API. Za pomocą tego identyfikatora Fiberpay przypisuje otrzymany przelew do zlecenia.Fiberpay powiadomi o otrzymaniu wpłaty wywołaniem callbacka podanego podczas tworzenia zlecenia.W ten sposób będziesz mógł wykonać czynności po stronie Twojego systemu.Środki zgromadzone z wszystkich przypisanych do zlecenia przelewów Fiberpay przekaże pojedynczym zagregowanym transferem na podane przez Ciebie konto bankowe.',
    linkUri: '/FiberCollect-20200923.pdf',
  },
  {
    name: 'FiberDirect',
    title: 'Szybki elektroniczny przekaz pieniężny zasilany przelewem bankowym',
    description: 'Szczegóły już wkrótce!',
    linkUri: '/FiberDirect-20210601.pdf',
  },
  {
    name: 'FiberForward',
    title:
      'Przekaz pieniężny na rzecz strony trzeciej z pobraniem opłaty dla Twojej Platformy',
    description: 'Szczegóły już wkrótce!',
    linkUri: '/FiberForward-20210204.pdf',
  },
  {
    name: 'FiberAccept',
    title:
      'Przyjmowanie płatności za pomocą szybkich przelewów (pay-by-link), BLIK i kart płatniczych',
    description: 'Szczegóły już wkrótce!',
    linkUri: null,
  },
  {
    name: 'FiberAccount',
    title:
      'Konta płatnicze z obsługą wpłat, wypłat i transferów pomiędzy kontami',
    description: 'Szczegóły już wkrótce!',
    linkUri: null,
  },
];

const ServiceDescription = ({ service }) => (
  <Grid key={service.name} container item xs={12}>
    <Grid item xs={12} sm={2}>
      <Typography variant='h6' color='primary.main'>
        {service.name}
      </Typography>
    </Grid>
    <Grid container item xs={12} sm={10} spacing={1}>
      <Grid item xs={12}>
        <Typography color='primary.main'>{service.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{service.description}</Typography>
      </Grid>
      {service.linkUri && (
        <Grid item xs={12}>
          <Link
            as='a'
            target='_blank'
            rel='noopener noreferrer'
            href={service.linkUri}
          >
            Przeczytaj regulamin usługi
          </Link>
        </Grid>
      )}
    </Grid>
  </Grid>
);

const Regulaminy = () => {
  return (
    <>
      <Seo title='Regulaminy Usług Płatniczych Fiberpay' />
      <header>
        <Toolbar disableGutters component='nav' sx={{ bgcolor: 'transparent', py: 2 }}>
          <BaseContainer>
            <Box display='flex' justifyContent='space-between'>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <GatsbyLink to='/'>
                  <FiberpayLogoImageCobalt />
                </GatsbyLink>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <Button as={GatsbyLink} to='/panel' variant={'contained'}>
                  Konto
                </Button>
              </Box>
            </Box>
          </BaseContainer>
        </Toolbar>
      </header>
      <main style={{ minHeight: '70vh' }}>
        <BaseContainer>
          <Box pb={4} py={4}>
            <Typography variant='h4' component='h1'>
              Regulaminy Usług Płatniczych Fiberpay
            </Typography>
          </Box>
          <Box py={4}>
            <Grid container spacing={6}>
              {services.map((s) => (
                <ServiceDescription service={s} key={s.name} />
              ))}
            </Grid>
          </Box>
        </BaseContainer>
      </main>
      <Footer />
    </>
  );
};

export default Regulaminy;
